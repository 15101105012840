




























import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import UiDataTable from '@/components/ui/UiDataTable.vue';
import { SubTree } from '@/utils/subTree';

import { ApiSvcSubsidiary, OrganizationsApi } from '../../../../generated/api-svc';
import { BaseVue } from '../../../BaseVue';
@Component({
  components: {
    UiDataTable,
  },
})
export default class SubsidiariesList extends BaseVue {
  @Prop({ default: false })
  public isLoading!: boolean;

  @Prop({ required: true })
  public subsidiaries!: ApiSvcSubsidiary[];

  public get subs() {
    if (this.subsidiaries && this.subsidiaries.length > 0) {
      const flat = SubTree.build(this.subsidiaries);
      return flat;
    } else {
      return [];
    }
  }

  public headers = [
    {
      id: 'name',
      label: 'Name',
      defaultVisibility: true,
    },
    {
      id: 'id',
      label: 'Id',
      defaultVisibility: true,
    },
  ];

  public classFromOffset(offset: number) {
    if (offset === 0) {
      return '';
    } else {
      return `pl-${offset * 2}`;
    }
  }
}
